import { Box } from '@mui/material';
import React, { useState } from 'react';
import { RPMExportPreviewTable } from './RPMExportPreviewTable';
import { RPMFilters } from './RPMFilters';
import { FilterConfiguration, Filters } from './rpms.types';

interface RPMExportPreviewProps {
  onAdvance: () => void;
}

const filterConfigurations = [
  {
    key: 'clinic',
    label: 'Clinic',
    options: [
      { value: 'mankato', label: 'Mankato' },
      { value: 'ehg', label: 'EHG' },
    ],
    type: 'select',
    multiple: true,
  },
  {
    key: 'insuranceCompany',
    label: 'Insurance Company',
    options: [
      { value: 'aetna', label: 'Aetna' },
      { value: 'blue_cross', label: 'Blue Cross' },
    ],
    type: 'autocomplete',
    includeSelectAll: true,
    multiple: true,
  },
  {
    key: 'ackDate',
    label: 'Ack Date',
    type: 'date',
  },
  {
    key: 'pos',
    label: 'POS (Place of Service)',
    options: [
      { value: '11', label: 'Office' },
      { value: '12', label: 'Home' },
    ],
    type: 'select',
    includeSelectAll: true,
    multiple: true,
  },
  {
    key: 'cptCodes',
    label: 'CPT Codes',
    options: [
      { value: '99454', label: '99454' },
      { value: '99457', label: '99457' },
    ],
    type: 'autocomplete',
    includeSelectAll: true,
    multiple: true,
  },
  {
    key: 'memberSearch',
    label: 'Member Search',
    options: [],
    type: 'autocomplete',
  },
] as FilterConfiguration<Filters>[];

const initialFilters: Filters = {
  clinic: [],
  insuranceCompany: [],
  ackDate: [],
  pos: [],
  cptCodes: [],
  memberSearch: [],
};

export const RPMExportPreview: React.FC<RPMExportPreviewProps> = ({ onAdvance }) => {
  const [filters, setFilters] = useState(initialFilters);

  const handleApplyFilters = (appliedFilters: typeof initialFilters) => {
    console.log('Applied Filters:', appliedFilters);
    setFilters(appliedFilters);
  };

  const handleProcessFilters = () => {
    onAdvance();
    console.log('Processing filters...');
    // Add logic to process filters
  };

  return (
    <Box>
      <RPMFilters<Filters>
        configurations={filterConfigurations}
        onApplyFilters={handleApplyFilters}
        onProcessFilters={handleProcessFilters}
        onProcessLabel='Export Claims'
        initialFilters={initialFilters}
      />
      <RPMExportPreviewTable />
    </Box>
  );
};
