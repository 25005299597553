import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Tab, Tabs, TabsProps } from '@mui/material';
import React from 'react';
import { customTabStyles } from './styles';

type tabLabel = {
  label: string;
  icon?: React.ReactNode;
};

interface CustomTabsProps extends TabsProps {
  tabLabels: tabLabel[];
}

class CustomTabsComponent extends React.Component<
  CustomTabsProps & WithStyles<typeof customTabStyles>
> {
  render() {
    const { classes, tabLabels, value, onChange, ...props } = this.props;

    return (
      <Tabs value={value} onChange={onChange} className={classes.tabs} {...props}>
        {tabLabels.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            icon={React.isValidElement(tab.icon) ? tab.icon : undefined}
            iconPosition='start'
          />
        ))}
      </Tabs>
    );
  }
}

export const CustomTabs = withStyles(customTabStyles)(CustomTabsComponent);
