import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import React from 'react';
import { Patient } from './rpms.types';

interface PatientDetailsModalProps {
  open: boolean;
  onClose: () => void;
  patient: Patient | null;
}

export const PatientDetailsModal: React.FC<PatientDetailsModalProps> = ({
  open,
  onClose,
  patient,
}) => {
  if (!patient) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontSize: '1.5rem',
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        Patient Details
        <IconButton onClick={onClose} sx={{ color: 'inherit' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                border: '1px solid #e0e0e0',
                borderRadius: 2,
                padding: 2,
              }}>
              <Box mb={2}>
                <Typography variant='h6' fontWeight='bold' gutterBottom>
                  Name: {patient.name}
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography>
                  ID:{' '}
                  <Link href={`#`} target='_blank' rel='noopener noreferrer'>
                    {patient.id}
                  </Link>
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography>Clinic: {patient.clinic}</Typography>
              </Box>
              <Box mb={1}>
                <Typography>ICD10 Codes:</Typography>
                <Box mt={1}>
                  {patient.icd10Codes.map((code) => (
                    <Chip
                      key={code}
                      label={code}
                      sx={{
                        marginRight: 1,
                        marginBottom: 1,
                        backgroundColor: '#f0f0f0',
                      }}
                    />
                  ))}
                </Box>
              </Box>
              <Box mb={1}>
                <Typography>
                  Membership ID:{' '}
                  <Link href={`#`} target='_blank' rel='noopener noreferrer'>
                    {patient.membershipId}
                  </Link>
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography>Membership Start: {patient.membershipStart}</Typography>
              </Box>
              <Box mb={1}>
                <Typography>Membership Status: {patient.membershipStatus}</Typography>
              </Box>
              <Box mb={1}>
                <Typography fontWeight='bold'>Available RPM CPT Codes:</Typography>
                <Box mt={1}>
                  {patient.cptCodes.map((code) => (
                    <Chip
                      key={code}
                      label={code}
                      sx={{
                        marginRight: 1,
                        marginBottom: 1,
                        backgroundColor: '#f0f0f0',
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                border: '1px solid #e0e0e0',
                borderRadius: 2,
              }}>
              <Box
                mb={2}
                sx={{
                  borderBottom: '1px solid #e0e0e0',
                  padding: 2,
                }}>
                <Box mb={2}>
                  <Typography variant='h6' fontWeight='bold' gutterBottom>
                    RPM Configuration Summary
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography>Opt-out Date: {patient.rpmConfiguration.optOutDate}</Typography>
                </Box>
                <Box mb={1}>
                  <Typography>
                    Disable RPM: {patient.rpmConfiguration.disableRPM ? 'Yes' : 'No'}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography>
                    Insurance Company:{' '}
                    <Link href={`#`} target='_blank' rel='noopener noreferrer'>
                      {patient.rpmConfiguration.insuranceCompany}
                    </Link>
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  padding: 2,
                  borderBottom: '1px solid #e0e0e0',
                }}>
                <Box mb={1}>
                  <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                    Insurance Company Configuration
                  </Typography>
                </Box>

                <Box pl={4} mt={1}>
                  <Typography variant='body2' sx={{ mb: 1 }}>
                    <strong>Disable RPM:</strong>
                    {patient.rpmConfiguration.insuranceConfig.disableRPM ? 'Yes' : 'No'}
                  </Typography>

                  {patient.rpmConfiguration.insuranceConfig.details.map((detail, index) => (
                    <Typography variant='body2' key={index} sx={{ mb: 1 }}>
                      <strong>{detail.code}:</strong> Disabled: {detail.disabled}, Max Claims/Cycle:{' '}
                      {detail.maxClaimsPerCycle}, POS: {detail.pos}
                    </Typography>
                  ))}
                </Box>
              </Box>

              <Box sx={{ padding: 2 }}>
                <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                  Clinic Specific Configuration
                </Typography>
                {patient.rpmConfiguration.clinicConfig.map((clinic, index) => (
                  <Box key={index} mb={2}>
                    <Typography variant='body1' fontWeight='bold'>
                      {clinic.clinicName}
                    </Typography>
                    <Box ml={2}>
                      {clinic.procedures.map((procedure, idx) => (
                        <Typography key={idx} variant='body2'>
                          {procedure.code}: {procedure.description}
                        </Typography>
                      ))}
                    </Box>
                    <Box ml={2}>
                      <Typography variant='body2' mt={1}>
                        Disable RPM: {clinic.disableRPM ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
