import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Grid, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import { ReusableAutocomplete } from './ReusableAutocomplete';
import { ReusableSelectFilter } from './ReusableSelectFilter';
import { FilterConfiguration } from './rpms.types';

interface FilterProps<T extends Record<string, string[]>> {
  configurations: FilterConfiguration<T>[];
  onApplyFilters: (filters: T) => void;
  onProcessFilters?: () => void;
  onProcessLabel?: string;
  initialFilters: T;
}

export const RPMFilters = <T extends Record<string, string[]>>({
  configurations,
  onApplyFilters,
  onProcessFilters,
  onProcessLabel = 'Process',
  initialFilters,
}: FilterProps<T>) => {
  const [filters, setFilters] = useState<T>(initialFilters);

  const handleFilterChange = <K extends keyof T>(key: K, value: T[K]) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleClearFilters = () => {
    setFilters(initialFilters);
    onApplyFilters(initialFilters);
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Dynamic Filter Fields */}
      <Grid container spacing={2}>
        {configurations.map((config) => (
          <Grid key={config.key as string} item xs={12} sm={6} md={3}>
            {config.type === 'autocomplete' ? (
              <ReusableAutocomplete
                label={config.label}
                options={config.options!}
                value={filters[config.key]}
                onChange={(value) => handleFilterChange(config.key, value as T[keyof T])}
                multiple={config.multiple ?? false}
                includeSelectAll={config.includeSelectAll ?? false}
              />
            ) : config.type === 'select' ? (
              <ReusableSelectFilter
                label={config.label}
                name={config.key as string}
                section='Dynamic Filters'
                value={filters[config.key]}
                onChange={(value: string[]) => handleFilterChange(config.key, value as T[keyof T])}
                options={config.options!}
              />
            ) : config.type === 'date' ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={config.label}
                  value={filters[config.key]}
                  onChange={(value) => handleFilterChange(config.key, value as T[keyof T])}
                  renderInput={(params) => <TextField {...params} variant='filled' fullWidth />}
                />
              </LocalizationProvider>
            ) : null}
          </Grid>
        ))}
      </Grid>

      {/* Action Buttons */}
      <Grid container spacing={2} mt={2}>
        {/* Left Buttons */}
        <Grid item xs={12} sm={6} md={6}>
          <Box display='flex' gap={2}>
            <Button
              color='primary'
              fullWidth
              variant='contained'
              onClick={() => onApplyFilters(filters)}
              startIcon={<FilterAltOutlinedIcon />}>
              Apply Filters
            </Button>
            <Button
              color='primary'
              fullWidth
              variant='contained'
              onClick={handleClearFilters}
              startIcon={<RefreshIcon />}>
              Clear Filters
            </Button>
          </Box>
        </Grid>

        {/* Right Buttons */}
        <Grid item xs={12} sm={6} md={6}>
          <Box display='flex' justifyContent='flex-end' gap={2}>
            {onProcessFilters && (
              <Button variant='contained' onClick={onProcessFilters} endIcon={<NavigateNextIcon />}>
                {onProcessLabel}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
