import { Box } from '@mui/material';
import React, { useState } from 'react';
import { PatientSelectionTable } from './PatientSelectionTable';
import { RPMFilters } from './RPMFilters';
import { FilterConfiguration, Filters } from './rpms.types';

interface PatientSelectionProps {
  onAdvance: () => void;
}

const filterConfigurations = [
  {
    key: 'period',
    label: 'Period',
    options: [
      { value: 'last_month', label: 'Last Month' },
      { value: 'this_month', label: 'This Month' },
    ],
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'clinic',
    label: 'Clinic',
    options: [
      { value: 'mankato', label: 'Mankato' },
      { value: 'ehg', label: 'EHG' },
    ],
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'insuranceCompany',
    label: 'Insurance Company',
    options: [
      { value: 'aetna', label: 'Aetna' },
      { value: 'blue_cross', label: 'Blue Cross' },
    ],
    section: 'Patient Selection',
    type: 'autocomplete',
  },
  {
    key: 'cptCodes',
    label: 'CPT Codes',
    options: [
      { value: '99454', label: '99454' },
      { value: '99457', label: '99457' },
    ],
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'icd10Codes',
    label: 'ICD10 Codes',
    options: [
      { value: 'e66.01', label: 'E66.01' },
      { value: 'z68.41', label: 'Z68.41' },
    ],
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'rpmStatus',
    label: 'RPM Status',
    options: [
      { value: 'valid', label: 'Valid' },
      { value: 'invalid', label: 'Invalid' },
    ],
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'rpmIssues',
    label: 'RPM Issues',
    options: [
      { value: 'opt_out', label: 'Opt-out' },
      { value: 'missing_icd10', label: 'Missing ICD10' },
    ],
    section: 'Patient Selection',
    type: 'autocomplete',
  },
  {
    key: 'memberSearch',
    label: 'Member Search',
    options: [],
    section: 'Patient Selection',
    type: 'autocomplete',
    includeSelectAll: false,
  },
] as FilterConfiguration<Filters>[];

const initialFilters: Filters = {
  period: [],
  clinic: [],
  icd10Codes: [],
  rpmStatus: [],
  insuranceCompany: [],
  cptCodes: [],
  rpmIssues: [],
};

export const PatientSelection: React.FC<PatientSelectionProps> = ({ onAdvance }) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [filters, setFilters] = useState<Filters>(initialFilters);

  const handleApplyFilters = (newFilters: Filters) => {
    console.log('Filters applied:', newFilters);
    setFilters(newFilters);
  };

  const handleProcessFilters = () => {
    if (selectedRows.length === 0) {
      alert('Please select at least one row before proceeding.');
      return;
    }
    onAdvance();
  };

  return (
    <Box>
      <RPMFilters<Filters>
        configurations={filterConfigurations}
        onApplyFilters={handleApplyFilters}
        onProcessFilters={handleProcessFilters}
        onProcessLabel='RPM Export Preview'
        initialFilters={initialFilters}
      />

      <PatientSelectionTable filters={filters} onRowSelectionChange={setSelectedRows} />
    </Box>
  );
};
