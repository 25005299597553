import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { CodeChipList } from './CodeChipList';

// Mock Data
const mockData = Array.from({ length: 15 }, (_, index) => ({
  id: 40600 + index,
  name: `Patient ${index + 1}`,
  clinic: `Clinic ${index % 3}`,
  insurance: `Insurance ${index % 2}`,
  claimId: index % 2 === 0 ? '324123' : 'Failed',
  ackDate: '12/31/2024',
  pos: 11,
  cptCodes: ['99454', '99457', '90091', '99458'],
  note: 'View Note',
  amount: `$${(Math.random() * 200).toFixed(2)}`,
  issues: index % 2 === 0 ? '' : '⚠️ 99019: Can’t be billed along with 99457',
}));

export const RPMExportResultsTable: React.FC = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedRows = mockData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4}></TableCell>
              <TableCell
                colSpan={6}
                align='center'
                sx={{ fontWeight: 'bold', border: '1px solid #e0e0e0' }}>
                Calculated claims for the period
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ borderLeft: '1px solid #e0e0e0' }}>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Clinic</TableCell>
              <TableCell>Insurance</TableCell>
              <TableCell sx={{ borderLeft: '1px solid #e0e0e0' }}>Claim ID</TableCell>
              <TableCell>Ack Date</TableCell>
              <TableCell>POS</TableCell>
              <TableCell>CPT Codes</TableCell>
              <TableCell>Note</TableCell>
              <TableCell sx={{ borderRight: '1px solid #e0e0e0' }}>Amount</TableCell>
              <TableCell sx={{ borderRight: '1px solid #e0e0e0', borderTop: '1px solid #e0e0e0' }}>
                Issues
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ border: '1px solid #e0e0e0' }}>
            {displayedRows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Link href='#' target='_blank' rel='noopener noreferrer'>
                    {row.id}
                  </Link>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.clinic}</TableCell>
                <TableCell>{row.insurance}</TableCell>
                <TableCell sx={{ borderLeft: '1px solid #e0e0e0' }}>
                  {row.claimId === 'Failed' ? (
                    <Tooltip title='Claim failed' arrow>
                      <Typography color='error' sx={{ cursor: 'pointer' }}>
                        {row.claimId}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Link href='#' target='_blank' rel='noopener noreferrer'>
                      {row.claimId}
                    </Link>
                  )}
                </TableCell>
                <TableCell>{row.ackDate}</TableCell>
                <TableCell>{row.pos}</TableCell>
                <TableCell>
                  <CodeChipList codes={row.cptCodes} />
                </TableCell>
                <TableCell>
                  <Link href='#' target='_blank' rel='noopener noreferrer'>
                    {row.note}
                  </Link>
                </TableCell>
                <TableCell sx={{ borderRight: '1px solid #e0e0e0' }}>{row.amount}</TableCell>
                <TableCell>{row.issues}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component='div'
        count={mockData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
