import {
  Checkbox,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CodeChipList } from './CodeChipList';
import { patientSelectionMockData } from './mockdata';
import { PatientDetailsModal } from './PatientDetailsModal';
import { Filters, Patient } from './rpms.types';

interface PatientSelectionTableProps {
  filters: Filters;
  onRowSelectionChange: (selectedRows: number[]) => void;
}

const getChipColor = (status: string) => {
  switch (status) {
    case 'Valid':
      return 'success';
    case 'Invalid':
      return 'error';
    default:
      return 'default';
  }
};

export const PatientSelectionTable: React.FC<PatientSelectionTableProps> = ({
  filters,
  onRowSelectionChange,
}) => {
  const [dataPatients, setPatientsData] = useState<Patient[]>([]);
  const [filteredData, setFilteredData] = useState<Patient[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setTimeout(() => {
        setPatientsData(patientSelectionMockData);
      }, 1000);
    };

    fetchData();
  }, []);

  useEffect(() => {
    let tempData = [...dataPatients];

    if (filters.clinic.length > 0) {
      tempData = tempData.filter((patient) =>
        filters.clinic.includes(patient.clinic.toLowerCase()),
      );
    }

    if (filters.insuranceCompany.length > 0) {
      tempData = tempData.filter((patient) =>
        filters.insuranceCompany.includes(patient.insurance.toLowerCase()),
      );
    }

    setFilteredData(tempData);
  }, [dataPatients, filters]);

  const handleRowSelection = (id: number) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
    onRowSelectionChange(updatedSelectedRows);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = (patient: Patient) => {
    setSelectedPatient(patient);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedPatient(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ border: '1px solid #e0e0e0' }} size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Select</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Clinic</TableCell>
              <TableCell>Insurance</TableCell>
              <TableCell>Available RPM CPT Codes</TableCell>
              <TableCell>ICD10 Codes</TableCell>
              <TableCell>RPM Status</TableCell>
              <TableCell>RPM Status Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Checkbox
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleRowSelection(row.id)}
                  />
                </TableCell>
                <TableCell>
                  <Link component='button' variant='body2' onClick={() => handleOpenModal(row)}>
                    {row.id}
                  </Link>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.clinic}</TableCell>
                <TableCell>{row.insurance}</TableCell>
                <TableCell>
                  <CodeChipList codes={row.cptCodes} />
                </TableCell>
                <TableCell>
                  <CodeChipList codes={row.icd10Codes} />
                </TableCell>
                <TableCell>
                  <Chip label={row.rpmStatus} color={getChipColor(row.rpmStatus)} />
                </TableCell>
                <TableCell>{row.rpmStatusDetails}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component='div'
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* Modal */}
      <PatientDetailsModal
        open={isModalOpen}
        onClose={handleCloseModal}
        patient={selectedPatient}
      />
    </>
  );
};
