import {
  Box,
  Checkbox,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { CodeChipList } from './CodeChipList';

// Mock Data
const mockData = Array.from({ length: 25 }, (_, index) => ({
  id: 40600 + index,
  name: `Patient ${index + 1}`,
  clinic: `Clinic ${index % 3}`,
  insurance: `Insurance ${index % 2}`,
  ackDate: '12/31/2024',
  pos: 11,
  cptCodes: ['99454', '99457', '90091', '99458'],
  note: 'View Note',
  issues: `⚠️ Issue ${index + 1}`,
}));

export const RPMExportPreviewTable: React.FC = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedRows = mockData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box>
      {/* Table Section */}
      <TableContainer>
        <Table size='small'>
          <TableHead>
            {/* Grouped Header */}
            <TableRow>
              <TableCell colSpan={4}></TableCell>
              <TableCell
                colSpan={5}
                align='center'
                sx={{ fontWeight: 'bold', border: '1px #e0e0e0 solid' }}>
                Calculated claims for the period
              </TableCell>
            </TableRow>
            {/* Standard Header */}
            <TableRow sx={{ border: '1px #e0e0e0 solid' }}>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Clinic</TableCell>
              <TableCell>Insurance</TableCell>
              <TableCell sx={{ borderLeft: '1px #e0e0e0 solid' }}>Select</TableCell>
              <TableCell>Ack Date</TableCell>
              <TableCell>POS</TableCell>
              <TableCell>CPT Codes</TableCell>
              <TableCell sx={{ borderRight: '1px #e0e0e0 solid' }}>Note</TableCell>
              <TableCell>Issues</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ border: '1px #e0e0e0 solid' }}>
            {displayedRows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Link href='#' target='_blank' rel='noopener noreferrer'>
                    {row.id}
                  </Link>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.clinic}</TableCell>
                <TableCell>{row.insurance}</TableCell>
                <TableCell sx={{ borderLeft: '1px #e0e0e0 solid' }}>
                  <Checkbox />
                </TableCell>
                <TableCell>{row.ackDate}</TableCell>
                <TableCell>{row.pos}</TableCell>
                <TableCell>
                  <CodeChipList codes={row.cptCodes} />
                </TableCell>
                <TableCell sx={{ borderRight: '1px #e0e0e0 solid' }}>
                  <Link href='#' target='_blank' rel='noopener noreferrer'>
                    {row.note}
                  </Link>
                </TableCell>
                <TableCell>{row.issues}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component='div'
        count={mockData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
