import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { StyledChip, TooltipText } from './styles';

interface CodeChipListProps {
  codes: string[];
}

export const CodeChipList: React.FC<CodeChipListProps> = ({ codes }) => {
  return (
    <Box>
      {codes.slice(0, 3).map((code) => (
        <StyledChip key={code} label={code} />
      ))}
      {codes.length > 3 && (
        <Tooltip
          title={
            <Box>
              {codes.slice(3).map((code) => (
                <StyledChip key={code} label={code} />
              ))}
            </Box>
          }
          arrow>
          <TooltipText>+{codes.length - 3}</TooltipText>
        </Tooltip>
      )}
    </Box>
  );
};
