import Looks3Icon from '@mui/icons-material/Looks3';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { CustomTabs } from '../common/CustomTabs/CustomTabs';
import { PatientSelection } from './PatientSelection';
import { RPMExportPreview } from './RPMExportPreview';
import { RPMExportResults } from './RPMExportResults';

function TabPanel(props: { children?: React.ReactNode; value: number; index: number }) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export const TabsComponent: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [enabledTabs, setEnabledTabs] = useState([0]);

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    if (enabledTabs.includes(newIndex)) {
      setTabIndex(newIndex);
    }
  };

  const enableAndSwitchTab = (index: number) => {
    setEnabledTabs((prev) => (prev.includes(index) ? prev : [...prev, index]));
    setTabIndex(index);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <CustomTabs
        tabLabels={[
          { label: 'Patient Selection', icon: <LooksOneIcon /> },
          { label: 'RPM Export Preview', icon: <LooksTwoIcon /> },
          { label: 'RPM Export Results', icon: <Looks3Icon /> },
        ]}
        value={tabIndex}
        onChange={handleTabChange}
        aria-label='RPM Process Tabs'
        sx={{
          '& .MuiTab-root': {
            color: (theme) =>
              enabledTabs.includes(tabIndex) ? theme.palette.text.primary : '#ccc',
          },
        }}
      />
      <TabPanel value={tabIndex} index={0}>
        <PatientSelection onAdvance={() => enableAndSwitchTab(1)} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <RPMExportPreview onAdvance={() => enableAndSwitchTab(2)} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <RPMExportResults />
      </TabPanel>
    </Box>
  );
};
