import { Autocomplete, Checkbox, ListItemText, TextField } from '@mui/material';
import React from 'react';

interface Option {
  value: string;
  label: string;
}

interface ReusableAutocompleteProps {
  options: Option[];
  label: string;
  value: string[];
  onChange: (values: string[]) => void;
  placeholder?: string;
  multiple?: boolean;
  disabled?: boolean;
  includeSelectAll?: boolean;
}

export const ReusableAutocomplete: React.FC<ReusableAutocompleteProps> = ({
  options,
  label,
  value = [],
  onChange,
  placeholder = '',
  multiple = true,
  disabled = false,
  includeSelectAll = false,
}) => {
  const all = 'all';
  const allOption = { value: all, label: 'All' };
  const allValues = options.map((opt) => opt.value) || [];

  const handleChange = (event: React.SyntheticEvent, newValue: string[]) => {
    const isAllSelected = newValue.includes(all);
    const wasAllSelected = value.includes(all);

    if (includeSelectAll) {
      if (isAllSelected && !wasAllSelected) {
        onChange([all, ...allValues]);
      } else if (!isAllSelected && wasAllSelected) {
        onChange([]);
      } else {
        const filteredValues = newValue.filter((v) => v !== all) || [];
        const updatedValues =
          filteredValues.length === allValues.length ? [all, ...filteredValues] : filteredValues;
        onChange(updatedValues);
      }
    } else {
      onChange(newValue);
    }
  };

  const selectableOptions = includeSelectAll ? [allOption, ...options] : options;

  return (
    <Autocomplete
      size='small'
      multiple={multiple}
      disableCloseOnSelect
      limitTags={1}
      options={selectableOptions.map((opt) => opt.value)}
      getOptionLabel={(option) =>
        option === all ? allOption.label : options.find((opt) => opt.value === option)?.label || ''
      }
      value={
        includeSelectAll && value.length === allValues.length && !value.includes(all)
          ? [all, ...value]
          : value
      }
      onChange={(event, newValue) => handleChange(event, newValue as string[])}
      disableClearable={!multiple}
      disabled={disabled}
      renderInput={(params) => (
        <TextField {...params} variant='filled' label={label} placeholder={placeholder} fullWidth />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox checked={option === all ? value.includes(all) : value.includes(option)} />
          <ListItemText
            primary={
              option === all ? allOption.label : options.find((opt) => opt.value === option)?.label
            }
          />
        </li>
      )}
    />
  );
};
