import { Admin, Resource } from 'react-admin';
import authProvider from './authProvider';
import Eligibility from './components/eligibility/Eligibility';
import { CustomLayout } from './config/customLayout';
import { customRoutes } from './config/customRoutes';
import dataProvider from './dataProvider';
import { AuthService } from './services/auth';
import { theme } from './styles/theme';
import { ROLES } from './util/constants';

const dataProviderLive = dataProvider();

const App = () => {
  return (
    <Admin
      authProvider={authProvider}
      customRoutes={customRoutes}
      dataProvider={dataProviderLive}
      disableTelemetry
      layout={CustomLayout}
      theme={theme}>
      {() => [
        AuthService.checkRoleAccess(ROLES.BILLING)
          ? [<Resource name='eligibility' list={Eligibility} />]
          : [],
      ]}
    </Admin>
  );
};

export default App;
