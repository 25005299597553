import { CheckBox } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FilePresent from '@mui/icons-material/FilePresent';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { Menu, MenuItemLink } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { AuthService } from '../services/auth';
import MixpanelService from '../services/mixpanel';
import { ROLES } from '../util/constants';

export const CustomMenu = (props: any) => {
  const { pathname } = useLocation();

  return (
    <Menu {...props} dense>
      {AuthService.checkRoleAccess(ROLES.BILLING) && (
        <>
          <MenuItemLink
            to='/eligibility'
            primaryText='Eligibility'
            leftIcon={<CheckBox />}
            onClick={() => {
              MixpanelService.trackButtonClicked({
                Field: 'Eligibility',
                source: pathname,
                Type: 'link',
              });
            }}
          />

          <MenuItemLink
            to='/claims'
            primaryText='Claims'
            leftIcon={<AccountBalanceIcon />}
            onClick={() => {
              MixpanelService.trackButtonClicked({
                Field: 'Claims',
                source: pathname,
                Type: 'link',
              });
            }}
          />

          <MenuItemLink
            to='/rpms'
            primaryText='RPMs'
            leftIcon={<MonitorHeartIcon />}
            onClick={() => {
              MixpanelService.trackButtonClicked({
                Field: 'RPMs',
                source: pathname,
                Type: 'link',
              });
            }}
          />
        </>
      )}

      <MenuItemLink
        to='/referrals'
        primaryText='Referrals'
        leftIcon={<FilePresent />}
        onClick={() => {
          MixpanelService.trackButtonClicked({
            Field: 'Referrals',
            source: pathname,
            Type: 'link',
          });
        }}
      />
    </Menu>
  );
};
