import { Patient } from './rpms.types';

export const patientSelectionMockData: Patient[] = [
  {
    id: 40609,
    name: 'Maria Martinez',
    clinic: 'Mankato',
    insurance: 'Insurance Payer (Payer ID)',
    cptCodes: ['99454', '99457', '90091', '99458'],
    icd10Codes: ['E66.811', 'Z68.45', 'Z68.41', 'Z68.1', 'Z68.25'],
    rpmStatus: 'Valid',
    rpmStatusDetails: '',
    membershipId: '123456',
    membershipStart: '01/01/2021',
    membershipStatus: 'Active',
    rpmConfiguration: {
      optOutDate: 'No',
      disableRPM: 'No',
      insuranceCompany: 'Insurance Payer Co',
      insuranceConfig: {
        disableRPM: true,
        details: [
          {
            code: '99454',
            disabled: 'No',
            maxClaimsPerCycle: 3,
            pos: 12,
          },
          {
            code: '99457',
            disabled: 'No',
            maxClaimsPerCycle: 2,
            pos: 11,
          },
        ],
      },
      clinicConfig: [
        {
          clinicName: 'Mankato',
          procedures: [
            {
              code: '90091',
              description: 'Disabled: no, max claims/cycle: 1, POS: 2',
            },
            {
              code: '99458',
              description: 'Disabled: yes, max claims/cycle: 2, POS: 11',
            },
          ],
        },
        {
          clinicName: 'EHG Texas',
          procedures: [
            {
              code: '90091',
              description: 'Disabled: no, max claims/cycle: 1, POS: 2',
            },
          ],
          disableRPM: true,
        },
      ],
    },
  },
  {
    id: 31054,
    name: 'Liam Davis',
    clinic: 'EHG Texas',
    insurance: 'Insurance Payer (Payer ID)',
    cptCodes: ['99454', '99458', '90091'],
    icd10Codes: ['E66.01', 'Z68.41'],
    rpmStatus: 'Invalid',
    rpmStatusDetails: 'Opt-out, Missing ICD10, Insurance RPM inactive',
    membershipId: '789012',
    membershipStart: '03/15/2022',
    membershipStatus: 'Inactive',
    rpmConfiguration: {
      optOutDate: 'Yes',
      disableRPM: 'Yes',
      insuranceCompany: 'Insurance Payer Co',
      insuranceConfig: {
        disableRPM: true,
        details: [
          {
            code: '99454',
            disabled: 'No',
            maxClaimsPerCycle: 3,
            pos: 12,
          },
          {
            code: '99457',
            disabled: 'No',
            maxClaimsPerCycle: 2,
            pos: 11,
          },
        ],
      },
      clinicConfig: [
        {
          clinicName: 'EHG Texas',
          procedures: [
            {
              code: '90091',
              description: 'Disabled: yes, max claims/cycle: 1, POS: 2',
            },
            {
              code: '99454',
              description: 'Disabled: no, max claims/cycle: 3, POS: 12',
            },
          ],
          disableRPM: true,
        },
      ],
    },
  },
];
