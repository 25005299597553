import { Box } from '@mui/material';
import { useState } from 'react';
import { RPMExportResultsTable } from './RPMExportResultsTable';
import { RPMFilters } from './RPMFilters';
import { FilterConfiguration, Filters } from './rpms.types';

const initialFilters = {
  clinic: [],
  insuranceCompany: [],
  ackDate: [],
  pos: [],
  cptCodes: [],
  memberSearch: [],
};

const filterConfigurations = [
  {
    key: 'clinic',
    label: 'Clinic',
    options: [
      { value: 'mankato', label: 'Mankato' },
      { value: 'ehg', label: 'EHG' },
    ],
    type: 'select',
  },
  {
    key: 'insuranceCompany',
    label: 'Insurance Company',
    options: [
      { value: 'aetna', label: 'Aetna' },
      { value: 'bluecross', label: 'Blue Cross' },
    ],
    type: 'autocomplete',
    includeSelectAll: true,
  },
  {
    key: 'ackDate',
    label: 'Ack Date',
    type: 'date',
  },
  {
    key: 'pos',
    label: 'POS (Place of Service)',
    options: [
      { value: '11', label: 'Office' },
      { value: '12', label: 'Home' },
    ],
    type: 'select',
  },
  {
    key: 'cptCodes',
    label: 'CPT Codes',
    options: [
      { value: '99454', label: '99454' },
      { value: '99457', label: '99457' },
    ],
    type: 'autocomplete',
    includeSelectAll: true,
  },
  {
    key: 'memberSearch',
    label: 'Member Search',
    options: [],
    type: 'autocomplete',
  },
] as FilterConfiguration<Filters>[];

export const RPMExportResults: React.FC = () => {
  const [filters, setFilters] = useState<Filters>(initialFilters);

  const handleApplyFilters = (appliedFilters: Filters) => {
    console.log('Applied Filters:', appliedFilters);
    setFilters(appliedFilters);
  };

  return (
    <Box>
      {/* Filters Section */}
      <RPMFilters<Filters>
        configurations={filterConfigurations}
        onApplyFilters={handleApplyFilters}
        initialFilters={initialFilters}
      />

      {/* Table Section */}
      <RPMExportResultsTable />
    </Box>
  );
};
