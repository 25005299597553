import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import { TrackableElement } from '../../services/mixpanel';

interface Option {
  value: string;
  label: string;
}

interface ReusableSelectFilterProps {
  label: string;
  section: string;
  name: string;
  value: string[];
  onChange: (values: string[]) => void;
  options: Option[];
  multiple?: boolean;
}

export const ReusableSelectFilter: React.FC<ReusableSelectFilterProps> = ({
  label,
  section,
  name,
  value,
  onChange,
  options,
  multiple = true,
}) => {
  const allOption = { value: 'all', label: 'All' };
  const allValues = options.map((opt) => opt.value);

  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    const { value: selected } = event.target;
    const selectedValues = typeof selected === 'string' ? selected.split(',') : selected;

    if (selectedValues.includes(allOption.value)) {
      const isAllSelected = value.length === allValues.length;
      onChange(isAllSelected ? [] : [...allValues]);
    } else {
      onChange(selectedValues.filter((val) => val !== allOption.value));
    }
  };

  return (
    <FormControl fullWidth variant='filled'>
      <InputLabel>{label}</InputLabel>
      <TrackableElement properties={{ Section: section, Name: name }}>
        <Select
          size='small'
          multiple={multiple}
          value={value}
          renderValue={(selected) =>
            (selected as string[])
              .map((val) =>
                val === allOption.value
                  ? allOption.label
                  : options.find((opt) => opt.value === val)?.label,
              )
              .join(', ')
          }
          onChange={handleSelectChange}>
          <MenuItem key={allOption.value} value={allOption.value}>
            <Checkbox checked={value.length === allValues.length} />
            <ListItemText primary={allOption.label} />
          </MenuItem>

          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={value.includes(option.value)} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </TrackableElement>
    </FormControl>
  );
};
