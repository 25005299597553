import { createTheme } from '@material-ui/core/styles';
import { StyledTheme } from './styleTheme';

export const theme = createTheme({
  palette: {
    primary: {
      main: StyledTheme.tealDark,
      contrastText: StyledTheme.white,
    },
    secondary: {
      main: StyledTheme.tealDark,
      contrastText: StyledTheme.white,
    },
  },
  typography: {
    fontFamily: StyledTheme.fontProximaNova,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        '&$containedPrimary': {
          backgroundColor: StyledTheme.tealDark,
          color: StyledTheme.white,
          '&:hover': {
            backgroundColor: '#237c96',
            textDecoration: 'none',
            cursor: 'pointer',
          },
        },
      },
      containedPrimary: {},
    },
    MuiTabs: {
      root: {
        borderBottom: '1px solid #d3d3d3',
      },
      indicator: {
        backgroundColor: '#1a667a',
      },
    },
    MuiTab: {
      root: {
        color: '#757575',
      },
      selected: {
        color: '#1a667a !important',
      },
    },
  },
});
