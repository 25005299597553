import { Theme } from '@emotion/react';
import { createStyles } from '@mui/material';

export const customTabStyles = (theme: Theme) =>
  createStyles({
    tabs: {
      borderBottom: '1px solid #d3d3d3',
      '& .MuiTab-root': {
        color: '#757575',
      },
      '& .MuiTab-root.Mui-selected': {
        color: '#1a667a',
      },
      '& .MuiTabs-indicator': {
        backgroundColor: '#1a667a',
      },
    },
  });
